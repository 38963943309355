import { GripperController } from "./gripperController";
import { JointController } from "./jointController";
import { Database, ref, onValue, set, runTransaction, Unsubscribe } from "firebase/database"

const maxTicks = 4096;
const gripperOpen = 2070;
const gripperClosed = 3349;
const ticksDegreesCoefficient = 11.377777777777778;
const maxLatencyMs = 10000;
function wait(ms: number) {
  return new Promise(r => setTimeout(r, ms));
}
function degreesToTicks(degrees: number): number {
  return Math.ceil((degrees + 180) * ticksDegreesCoefficient);
}
function ticksToDegrees(ticks: number): number {
  return ticks / ticksDegreesCoefficient - 180;
}
function ticksToGripperState(ticks: number) {
  if (Math.abs(ticks - gripperClosed) < Math.abs(ticks - gripperOpen)) {
    return 0;
  }
  return 1;
}
export interface Session {
  id: number;
  commands: RobotCommand[];
  total_moves: number;
}
export interface MotorsState {
  m12?: number;
  m13?: number;
  m14?: number;
  m15?: number;
}
enum RobotStatus {
  busy = 'bysy',
  ready = 'ready'
}
export interface RobotState {
  motors: MotorsState;
  state: RobotStatus;
  executingCommand?: RobotCommand;
  ping: number
}

export interface RobotCommand {
  motors: MotorsState;
}
enum RobotMode {
  singleCommand = 'singleCommand',
  multiCommand = 'multiCommand',
}
export class RobotController {
  shoulderController: JointController;
  elbowController: JointController;
  wristController: JointController;
  gripperController: GripperController;
  movesEl: HTMLElement;
  submitEl: HTMLButtonElement;
  connectionTextEl: HTMLElement;
  connectionGoodImageEl: HTMLImageElement;
  connectionBadImageEl: HTMLImageElement;
  mode: RobotMode;
  private _robotListenerStop: Unsubscribe;
  private _pingListenerStop: Unsubscribe;
  private _sessionListenerStop: Unsubscribe;
  private _lastPing: number;
  private _isConnected: boolean = false;
  
  public get isConnected(): boolean {
    return this._isConnected;
  }
  public set isConnected(value: boolean) {
    this._isConnected = value;
    if (this._isConnected) {
      this.connectionGoodImageEl.style.removeProperty('display');
      this.connectionBadImageEl.style.display = 'none';
      this.connectionTextEl.innerText = 'Connected';
    } else {
      this.connectionBadImageEl.style.removeProperty('display');
      this.connectionGoodImageEl.style.display = 'none';
      this.connectionTextEl.innerText = 'Connected';
      this.connectionTextEl.innerText = 'Offline'
    }
  }

  protected _robotState: RobotState; 
  public get robotState(): RobotState {
    return this._robotState;
  }
  public set robotState(value: RobotState) {
    this._robotState = value;
    this.applyRobotState()
  }
  protected _sessionId: number;
  public get sessionId(): number {
    return this._sessionId;
  }
  public set sessionId(value: number) {
    this._sessionId = value;
    this.listenForSession();
  }
  protected _disabled: boolean;
  public get disabled(): boolean {
    return this._disabled;
  }
  
  public set disabled(v: boolean) {
    console.log('disabled:', v);
    this._disabled = v;
    this.shoulderController.disabled = v;
    this.elbowController.disabled = v;
    this.wristController.disabled = v;
    this.gripperController.disabled = v;
    this.submitEl.disabled = v;
    if (v) {
      this.submitEl.innerText = 'BUSY';
    } else {
      this.submitEl.innerText = 'RUN';
    }
  }

  constructor(public element: HTMLElement, protected database: Database) {
    this.mode = RobotMode.singleCommand;
    this.start();
  }

  protected bind(): void {

    const shoulderMotorEl = document.getElementById('motor-12') as HTMLDivElement;
    const elbowMotorEl = document.getElementById('motor-13') as HTMLDivElement;
    const wristMotorEl = document.getElementById('motor-14') as HTMLDivElement;
    const gripperEl = document.getElementById('motor-gripper') as HTMLDivElement;
    this.shoulderController = new JointController(shoulderMotorEl, 'Shoulder', 8, -180, 180, 0);
    this.elbowController = new JointController(elbowMotorEl, 'Elbow', 4, -180, 180, 0);
    this.wristController = new JointController(wristMotorEl, 'Wrist', 2, -180, 180, 0);
    this.gripperController = new GripperController(gripperEl, 'Gripper', 1);
    this.movesEl = this.element.getElementsByClassName('moves-counter').item(0) as HTMLElement;
    this.submitEl = this.element.getElementsByClassName('submit-button').item(0) as HTMLButtonElement;
    this.submitEl.onclick = () => { this.submit() };
    this.connectionGoodImageEl = this.element.getElementsByClassName('connection-image-good').item(0) as HTMLImageElement;
    this.connectionBadImageEl = this.element.getElementsByClassName('connection-image-bad').item(0) as HTMLImageElement;
    this.connectionTextEl = this.element.getElementsByClassName('connection-text').item(0) as HTMLElement;
    if (this.mode === RobotMode.singleCommand) {
      this.submitEl.style.display = 'none';
      this.shoulderController.onGoalValueChanged = () => {
        this.submit();
      };
      this.elbowController.onGoalValueChanged = () => {
        this.submit();
      }
      this.wristController.onGoalValueChanged = () => {
        this.submit();
      }
      this.gripperController.onGoalValueChanged = () => {
        this.submit();
      }
    }
  }
  public start(): void {
    this.bind();
    this.watchRobot();
    this.listenForPing();
  }
  public stop(): void {
    if (this._robotListenerStop) {
      this._robotListenerStop();
    }
    if (this._pingListenerStop) {
      this._pingListenerStop();
    }
  }
  protected listenForPing(): void {
    this._robotListenerStop = onValue(ref(this.database, 'server/ping'), (snapshot) => {
      this._lastPing = snapshot.val();
    });
    setInterval(() => {
      if (this._lastPing !== undefined) {
        const latency = Math.ceil(new Date().getTime() - this._lastPing);
        const wasConnected = this.isConnected;
        this.isConnected = latency < maxLatencyMs;
        if (wasConnected && !this._isConnected) {
          console.warn(`❌ Disconnected! Latency ${latency}ms`);
        } else if (!wasConnected && this._isConnected) {
          console.log(`✅ Connected! Latency ${latency}ms`);
        }
      }
    }, 1000);
  }
  protected watchRobot(): void {
    console.log('watching robot state');
    this._robotListenerStop = onValue(ref(this.database, 'robot'), (snapshot) => {
      console.log('robot state', snapshot);
      this.robotState = snapshot.val();
    });
  }
  protected listenForSession(): void {
    if (this._sessionListenerStop) {
      this._sessionListenerStop();
    }
    const movesListener = onValue(ref(this.database, `session/${this.sessionId}/total_moves`), (snapshot) => {
      this.movesEl.innerText = snapshot.val() || '0';
    });
    this._sessionListenerStop = movesListener;
  }
  
  protected applyRobotState() {
    console.log('state', this.robotState);
    this.shoulderController.currentValue = -ticksToDegrees(this.robotState.motors.m12);
    this.elbowController.currentValue = ticksToDegrees(this.robotState.motors.m13);
    this.wristController.currentValue = ticksToDegrees(this.robotState.motors.m14);
    const m15 = this.robotState.motors.m15;
    this.gripperController.currentValue = ticksToGripperState(m15);
    this.disabled = this.robotState.state !== RobotStatus.ready;
  }
  protected async submit() {
    let commandCount = 0;
    const command: RobotCommand = { motors:{}};
    if (this.shoulderController.goalValue !== undefined) {
      command.motors.m12 = degreesToTicks(-this.shoulderController.goalValue);
      commandCount += 1;
    }
    if (this.elbowController.goalValue !== undefined) {
      command.motors.m13 = degreesToTicks(this.elbowController.goalValue);
      commandCount += 1;
    }
    if (this.wristController.goalValue !== undefined) {
      command.motors.m14 = degreesToTicks(this.wristController.goalValue);
      commandCount += 1;
    }
    if (this.gripperController.goalValue !== undefined) {
      command.motors.m15 = this.gripperController.goalValue === 1? gripperOpen : gripperClosed;
      commandCount += 1;
    }
    if (commandCount > 0) {
      console.log('robot command', command);
      await this.executeCommand(command);
    }
  }

  protected async executeCommand(command: RobotCommand) {
    this.disabled = true;
    try {
      const result = await runTransaction(ref(this.database, `session/${this.sessionId}/last_command_id`), (lastCommand) => {
        if (lastCommand) {
          return ++lastCommand;
        }
        return 1;
      });
      const nextCommand = result.snapshot.val();
      await set(ref(this.database, `session/${this.sessionId}/commands/${nextCommand}/motors`), command.motors)
      await this.waitForRobotState();
    } catch (e) {
      console.error(e);
    }
  }
  protected async waitForRobotState(state: RobotStatus = RobotStatus.ready) {
    while (this.robotState.state !== RobotStatus.ready) {
      await wait(1000);
    }
  }
}