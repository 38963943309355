import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { AppController } from "./components/appController";

const firebaseConfig = {
  apiKey: "AIzaSyCdmePQnbba_yFTRdDrrj5eK4ekhZ4ZJSo",
  authDomain: "armcontroller-63752.firebaseapp.com",
  databaseURL: "https://armcontroller-63752-default-rtdb.firebaseio.com",
  projectId: "armcontroller-63752",
  storageBucket: "armcontroller-63752.appspot.com",
  messagingSenderId: "97585747579",
  appId: "1:97585747579:web:93e72c00acf07bbffab4de"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase();

async function main() {
  const appController = new AppController(db);
  await appController.start();
}
window.onload = async function () {
  await main()  
  console.log("Hello");
}
