import { Database, get, onValue, ref, runTransaction, set, Unsubscribe } from "firebase/database";
import { CompleteController } from "./completeController";
import { QueueController } from "./queueController";
import { RobotController } from "./robotController";

enum AppPage {
  welcome = 'welcome',
  queue = 'queue',
  robot = 'robot',
  complete = 'complete'
}

export class AppController {
  protected _currentPage: AppPage;
  protected pageElements: Map<AppPage, HTMLElement>;
  protected sessionId: number;
  protected queueController: QueueController;
  protected robotController: RobotController;
  protected completeController: CompleteController;

  protected _sessionListenerStop: Unsubscribe;

  public get currentPage(): AppPage {
    return this._currentPage;
  }
  public set currentPage(value: AppPage) {
    this._currentPage = value;
    this.applyPage();
  }

  constructor(public database: Database) {
    this.pageElements = new Map<AppPage, HTMLElement>();
    this.bind();
  }
  protected bind() {
    this.pageElements.set(AppPage.welcome,document.getElementById('welcome'));
    this.pageElements.set(AppPage.queue, document.getElementById('queue'));
    this.pageElements.set(AppPage.robot, document.getElementById('active'));
    this.pageElements.set(AppPage.complete, document.getElementById('complete'));
    for (const logo of document.getElementsByClassName('logo')) {
      const logoEl = logo as HTMLElement;
      logoEl.onclick = (ev) => {
        console.log(ev.detail);
        if (confirm(`Your session is [${this.sessionId}]. Start again and loose your progress?`)) {
          this.reset();
        }
      };
    }
    this.robotController = new RobotController(this.pageElements.get(AppPage.robot), this.database);
    this.queueController = new QueueController(this.pageElements.get(AppPage.queue), this.database);
    this.completeController = new CompleteController(this.pageElements.get(AppPage.complete), this.database);
    this.completeController.onTryAgain = () => { this.reset(); };
    this.currentPage = AppPage.queue;
  }
  public async start() {
    await this.ensureSession();
    this.robotController.sessionId = this.sessionId;
    this.queueController.sessionId = this.sessionId;
    this.completeController.sessionId = this.sessionId;
  }
  protected listenForSession(): void {
    if (this._sessionListenerStop) {
      this._sessionListenerStop();
    }
    const statusListener = onValue(ref(this.database, `session/${this.sessionId}/status`), (snapshot) => {
      console.log('session is', snapshot.val())
      switch (snapshot.val()) {
        case 'active': {
          this.currentPage = AppPage.robot;
        } break;
        case 'complete': {
          this.currentPage = AppPage.complete;
        } break;
        default: {
          this.currentPage = AppPage.queue;
        }
      }
    });
    this._sessionListenerStop = statusListener;
  }
  protected applyPage() {
    for (const page of Array.from(this.pageElements.keys())) {
      const element = this.pageElements.get(page);
      if (element) {
        if (this.currentPage === page) {
          element.style.removeProperty('display');
        } else {
          element.style.display = 'none';
        }
      }
    }
  }
  protected async reset() {
    window.localStorage.removeItem('session_id');
    await this.start();
  }
  protected async ensureSession() {
    this.sessionId = undefined;
    const existingSession = window.localStorage.getItem('session_id');
    if (existingSession) {
      try {
        const existingSessionId = parseInt(existingSession);
        const sessionRef = ref(this.database, `session/${existingSessionId}`);
        const sessionSnap = await get(sessionRef);
        if (sessionSnap.exists()) {
          this.sessionId = sessionSnap.val()
        }
      } finally {}
    }
    if (!this.sessionId) {
      const lastSessionIdRef = ref(this.database, 'last_session_id');
      // const lastSession: number = (await get(lastSessionIdRef)).val()
      // console.log(`Last session ${lastSession}`)
      const result = await runTransaction(lastSessionIdRef, (lastSession) => {
        return ++lastSession;
      });
      const currentSessionId = result.snapshot.val()
      const sessionRef = ref(this.database, `session/${currentSessionId}/id`)
      await set(sessionRef, currentSessionId);
      window.localStorage.setItem('session_id', currentSessionId);
      this.sessionId = currentSessionId;
    } else {
      this.sessionId = parseInt(existingSession)
    }
    this.listenForSession();
  }
}