export class GripperController {
  protected _minusButton: HTMLButtonElement;
  protected _plusButton: HTMLButtonElement;
  protected _degrees: HTMLDivElement;
  protected _goalLabel: HTMLSpanElement;
  protected _itemLabel: HTMLSpanElement;

  protected _disabled: boolean;
  public onGoalValueChanged: (value: number) => void;
  public get disabled(): boolean {
    return this._disabled;
  }
  
  public set disabled(v: boolean) {
    this._disabled = v;
    this._plusButton.disabled = v;
    this._minusButton.disabled = v;
  }
  protected _currentValue: number;
  public get currentValue() {
    return this._currentValue;
  }
  public set currentValue(value: number) {
    this._currentValue = value;
    if (this._currentValue) {
      this._plusButton.classList.add('active');
      this._minusButton.classList.remove('active');
    } else {
      this._plusButton.classList.remove('active');
      this._minusButton.classList.add('active');
    }
    this.goalValue = undefined;

    // this._degreeLabel.innerText = `${degrees}°`
  }

  protected _goalValue: number;
  public get goalValue() {
    return this._goalValue;
  }
  public set goalValue(value: number) {
    if (value === 1) {
      this._goalLabel.innerText = 'Open';
    } else if (value === 0) {
      this._goalLabel.innerText = 'Close';
    } else {
      this._goalLabel.innerText = '';
    }
    if (value !== this.currentValue) {
      this._goalValue = value;
      if (this.onGoalValueChanged) {
        this.onGoalValueChanged(value);
      }
    } else {
      this._goalValue = undefined;
    }
  }

  constructor(
    public element: HTMLDivElement,
    public label: string,
    value: number = 0) {
    this.label = label;
    
    this.bind();
    this.currentValue = value;
  }
  protected bind(): void {
    this._goalLabel = this.element.getElementsByClassName('motor-degrees').item(0) as HTMLSpanElement;
    this._itemLabel = this.element.getElementsByClassName('motor-label').item(0) as HTMLSpanElement;
    this._minusButton = this.element.getElementsByClassName('close').item(0) as HTMLButtonElement;
    this._plusButton = this.element.getElementsByClassName('open').item(0) as HTMLButtonElement;

    this._plusButton.onclick = () => {
      this.plusClicked();
    }
    this._minusButton.onclick = () => {
      this.minusClicked();
    }
    this._itemLabel.innerText = this.label;
    
  }
  protected minusClicked(): void {
    this._minusButton.classList.add('active');
    this._plusButton.classList.remove('active');
    this.goalValue = 0;
  }
  protected plusClicked(): void {
    this._plusButton.classList.add('active');
    this._minusButton.classList.remove('active');
    this.goalValue = 1;
  }
}