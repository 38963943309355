import { Database, get, onValue, ref, Unsubscribe } from "firebase/database";

export class CompleteController {
  protected titleEl: HTMLElement;
  protected subtitleEl: HTMLElement;
  protected textEl: HTMLElement;
  protected ctaEl: HTMLElement;
  protected successBlockEl: HTMLElement;
  protected tryAgainButton: HTMLButtonElement;
  protected achievementMasterEl: HTMLElement;
  protected achievementOperatorEl: HTMLElement;
  protected _sessionListenerStop: Unsubscribe;
  protected twitterLinkEl: HTMLAnchorElement;
  protected facebookLinkEl: HTMLAnchorElement;
  protected linkedinLinkEl: HTMLAnchorElement;
  protected totalMoves: number;
  protected totalSeconds: number;
  
  protected _sessionId: number;
  public onTryAgain: () => void;
  public get sessionId(): number {
    return this._sessionId;
  }
  public set sessionId(value: number) {
    this._sessionId = value;
    this.listenForSession();
  }
  private _success: boolean;
  protected get success(): boolean {
    return this._success;
  }
  protected set success(value: boolean) {
    this._success = value;
    this.update();
  }

  constructor(public element: HTMLElement, public database: Database) {
    this.bind();
  }

  protected bind() {
    this.titleEl = this.element.getElementsByClassName('complete-title').item(0) as HTMLElement;
    this.subtitleEl = this.element.getElementsByClassName('subtitle').item(0) as HTMLElement;
    this.textEl = this.element.getElementsByClassName('success-text').item(0) as HTMLElement;
    this.ctaEl = this.element.getElementsByClassName('success-cta').item(0) as HTMLElement;
    this.successBlockEl = this.element.getElementsByClassName('success-block').item(0) as HTMLElement;
    this.achievementMasterEl = this.element.getElementsByClassName('achievement-master').item(0) as HTMLElement;
    this.achievementOperatorEl = this.element.getElementsByClassName('achievement-operator').item(0) as HTMLElement;
    this.twitterLinkEl = document.getElementById('twitter-share') as HTMLAnchorElement;
    this.facebookLinkEl = document.getElementById('facebook-share') as HTMLAnchorElement;;
    this.linkedinLinkEl = document.getElementById('linkedin-share') as HTMLAnchorElement;;
    this.tryAgainButton = this.element.getElementsByClassName('submit-button').item(0) as HTMLButtonElement;
    this.tryAgainButton.onclick = () => { this.onTryAgain(); };
  }

  protected listenForSession(): void {
    if (this._sessionListenerStop) {
      this._sessionListenerStop();
    }
    this._sessionListenerStop = onValue(ref(this.database, `session/${this.sessionId}/success`), async (snapshot) => {
      const [movesSnap, secondsSnap] = await Promise.all([
        get(ref(this.database, `session/${this.sessionId}/total_moves`)),
        get(ref(this.database, `session/${this.sessionId}/total_seconds`))]
      );
      this.totalMoves = movesSnap.val();
      this.totalSeconds = secondsSnap.val();
      this.success = snapshot.val();
    });
  }
  protected update(): void {
    if (this.success) {
      this.successBlockEl.style.removeProperty('display');
      const url = 'https://robot.ant61.com/achievement_master.html';
      const message = `I've completed a space repair mission today! #ANT61robot @ANT61COM`;
      this.twitterLinkEl.href = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(message)}`;
      this.facebookLinkEl.href = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
      this.linkedinLinkEl.href = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
      this.titleEl.innerText = 'Success';
      this.subtitleEl.innerText = `Impressive!`;
      this.textEl.innerText = `You've finished the task in ${this.totalSeconds} seconds using just ${this.totalMoves} Moves`;
      this.ctaEl.innerText = `It's an achievement worth bragging about!`;
      this.achievementMasterEl.style.removeProperty('display');
      this.achievementOperatorEl.style.display = 'none';
    } else if (this.success == false) {
      this.successBlockEl.style.removeProperty('display');
      const url = 'https://robot.ant61.com/achievement_operator.html';
      const message = `I've operated a space robot today! #ANT61robot @ANT61COM`;
      this.twitterLinkEl.href = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(message)}`;
      this.facebookLinkEl.href = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
      this.linkedinLinkEl.href = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
      this.titleEl.innerText = 'Nice try';
      this.subtitleEl.innerText = `Well, space is hard!`;
      this.textEl.innerText = `You've operated the robot for ${this.totalSeconds} seconds and made ${this.totalMoves} Moves`;
      this.ctaEl.innerText = `You can now call yourself the space robot operator 😎`; 
      this.achievementOperatorEl.style.removeProperty('display');
      this.achievementMasterEl.style.display = 'none';
    } else {
      this.successBlockEl.style.display = 'none';
      this.tryAgainButton.style.display = 'none';
      this.titleEl.innerText = 'Sit tight!';
      this.subtitleEl.innerText = `Your mission (#${this.sessionId}) results are loading...`;
      this.textEl.innerText = '';
      this.ctaEl.innerText = '';
      this.achievementOperatorEl.style.display = 'none';
      this.achievementMasterEl.style.display = 'none';
    }
  }
}