import { Database } from "firebase/database";

export class QueueController {
  protected sessionNumberEl: HTMLElement;

  protected _sessionId: number;
  public get sessionId(): number {
    return this._sessionId;
  }
  public set sessionId(value: number) {
    this._sessionId = value;
    this.sessionNumberEl.innerText = `${value}`;
  }
  
  constructor(public element:HTMLElement, public database: Database) {
    this.bind();
  }
  bind() {
    this.sessionNumberEl = this.element.getElementsByClassName('session-number').item(0) as HTMLElement;
  }
}