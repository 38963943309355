const degPerPixel = 18;
export class JointController {
  protected _minusButton: HTMLButtonElement;
  protected _plusButton: HTMLButtonElement;
  protected _degrees: HTMLDivElement;
  protected _goalPointer: HTMLDivElement;
  protected _degreeLabel: HTMLSpanElement;
  protected _motorLabel: HTMLSpanElement;
  protected _prevAction?: 'minus' | 'plus';
  protected _currentValue: number;
  
  protected _disabled: boolean;
  public onGoalValueChanged: (value: number) => void;
  public get disabled(): boolean {
    return this._disabled;
  }
  
  public set disabled(v: boolean) {
    this._disabled = v;
    this._plusButton.disabled = v;
    this._minusButton.disabled = v;
  }
  
  
  public get currentValue() {
    return this._currentValue;
  }
  public set currentValue(degrees: number) {
    this._currentValue = degrees;
    if (degrees > 0) {
      this._degrees.style.paddingRight = `${(degrees * degPerPixel * 4)}px`;
      this._degrees.style.paddingLeft = '0';
    } else {
      this._degrees.style.paddingRight = '0';
      this._degrees.style.paddingLeft = `${(-degrees * degPerPixel * 4)}px`;
    }
    this._degreeLabel.innerText = '';
    this.goalValue = undefined;
    this._prevAction = undefined;
    // this._degreeLabel.innerText = `${degrees}°`
  }

  protected _goalValue: number;
  public get goalValue() {
    return this._goalValue;
  }
  public set goalValue(degrees: number) {
    this._goalValue = degrees;
    if (this._goalValue === undefined || this._goalValue === this.currentValue) {
      this._plusButton.disabled = false || this.disabled;
      this._minusButton.disabled = false || this.disabled;
      this._goalValue = undefined;
      this._degreeLabel.innerText = '';
      return;
    }
    if (this._goalValue > this.currentValue) {
      this._plusButton.disabled = true;
      this._minusButton.disabled = false;
    } else if (this._goalValue < this.currentValue) {
      this._plusButton.disabled = false;
      this._minusButton.disabled = true;
    }
    this._degreeLabel.innerText = `${degrees.toFixed(0)}°`;
  }

  constructor(
    public element: HTMLDivElement,
    public label: string,
    public increment: number,
    public minValue: number,
    public maxValue: number,
    value: number = 0) {
    this.label = label;
    this.increment = increment;
    this.bind();
    this.generateRuler();
    this.currentValue = value;
  }
  protected bind(): void {
    this._degreeLabel = this.element.getElementsByClassName('motor-degrees').item(0) as HTMLSpanElement;
    this._motorLabel = this.element.getElementsByClassName('motor-label').item(0) as HTMLSpanElement;
    this._minusButton = this.element.getElementsByClassName('minus').item(0) as HTMLButtonElement;
    this._plusButton = this.element.getElementsByClassName('plus').item(0) as HTMLButtonElement;
    this._degrees = this.element.getElementsByClassName('degrees').item(0) as HTMLDivElement;
    this._goalPointer = this.element.getElementsByClassName('goal-pointer').item(0) as HTMLDivElement;
    this._plusButton.onclick = () => {
      this.plusClicked();
    }
    this._minusButton.onclick = () => {
      this.minusClicked();
    }
    this._motorLabel.innerText = this.label;
  }
  protected generateRuler(): void {
    let html = ''
    for (let i =this.minValue; i <= this.maxValue; i++) {
      html += `                  <div class="degree">
      <b class="major"></b>
    </div>
    <div class="degree">
      <label>${i}</label>
      <b></b>
    </div>`;
    }
    html += `                  <div class="degree">
    <b class="major"></b>
  </div>`;
    this._degrees.innerHTML = html;
  }
  protected minusClicked(): void {
    console.log('prev_action', this._prevAction);
    if (this._goalValue === undefined) {
      this._goalValue = this.currentValue;
    }
    if (this._prevAction === 'plus') {
      this.goalValue = undefined;
    } else {
      this.goalValue = Math.round(this.goalValue - this.increment);
      if (this.onGoalValueChanged) {
        this.onGoalValueChanged(this.goalValue);
      }
    }
    this._prevAction = 'minus';
    console.log('prev_action', this._prevAction, this.goalValue);
  }
  protected plusClicked(): void {
    console.log('prev_action', this._prevAction);
    if (this._goalValue === undefined) {
      this._goalValue = this.currentValue;
    }
    if (this._prevAction === 'minus') {
      this.goalValue = undefined;
    } else {
      this.goalValue = Math.round(this.goalValue + this.increment);
      if (this.onGoalValueChanged) {
        this.onGoalValueChanged(this.goalValue);
      }
    }
    this._prevAction = 'plus';
    console.log('prev_action', this._prevAction, this.goalValue);
  }
}